import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'center-content',
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Sponsorships',
    paragraph: 'Here is a list of our partnered companies. '
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
         <SectionHeader data={sectionHeader} className="center-content" />

         
          <div className={tilesClasses} style={{flexWrap:"inherit", alignItems: "center"}}>

            <div className="tiles-item reveal-from-bottom center-content" data-reveal-delay="400">
              <div className="features-tiles-item-header">
                <div className="mb-16">
                  <Image
                    src={"https://tigriseventsinc.com/wp-content/uploads/2010/05/BDC-Logo.png"}
                    alt="Features tile icon 02"
                    width={120}
                    height={120} />
                </div>
              </div>
            </div>
            
            <div className="tiles-item reveal-from-bottom center-content" data-reveal-delay="400">
              <div className="features-tiles-item-header">
                <div className="mb-16">
                  <Image
                    src={"https://liquidavataroperations.com/wp-content/uploads/2020/04/KABN-SNA-logo-med-w.png"}
                    alt="Features tile icon 02"
                    width={120}
                    height={120} />
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="features-tiles-item-header">
                <div className="mb-16">
                  <Image
                    src={"https://uploads-ssl.webflow.com/5eeb6f6c1cccc221e6619920/5f206069f54ae2ba6bc42566_white%20font%20logo.svg"}
                    alt="Features tile icon 02"
                    width={120}
                    height={120} />
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="features-tiles-item-header">
                <div className="mb-16">
                  <Image
                    src={"https://marsdd.com/wp-content/uploads/2019/03/MaRS_Logo_RGB_SMALL.png"}
                    alt="Features tile icon 02"
                    width={120}
                    height={120} />
                </div>
              </div>
            </div>
        </div>    

        <div className={tilesClasses} style={{flexWrap:"inherit", alignItems: "center"}}>

 

              <div className="tiles-item reveal-from-bottom center-content" data-reveal-delay="400">
                <div className="features-tiles-item-header">
                  <div className="mb-16">
                    <Image
                      src={"https://liquidavataroperations.com/wp-content/uploads/2021/04/Liquid-Avatar-Operations-inc-%C2%AE-Logo-Rev-FINAL.png"}
                      alt="Features tile icon 02"
                      width={120}
                      height={120} />
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
                <div className="features-tiles-item-header">
                  <div className="mb-16">
                    <Image
                      src={"https://docs.nvidia.com/metropolis/deepstream/5.0DP/python-api/_static/nvlogo_white.png"}
                      alt="Features tile icon 02"
                      width={120}
                      height={120} />
                  </div>
                </div>
              </div>


              
            </div>      

         {/*} 
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    As someone who works in tech, software delivery is a huge nightmare. Delays, modularity and integratibility are the biggest issues my team faces. We turned to matrix innovations to help, and they went above and beyond with their software delivery.
                      </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Sonny Singh </span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="#0">Nissan</a>
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    We needed a new architecture playground to help our designers keep up with environmental innovations and matrix innovations built us the product we needed. They created extensive documentation and conducted training classes for our employees.
                      </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Diana Winter</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="#0">Urban Designers</a>
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    Implementing and designing sorting machines at our landfill are above our engineers pay. We contracted Matrix Innovations and they built us a beta prototype which is currently in testing. Our engineers are glad as Matrix Innovations are easy to work with, and the product they deliver is above expected standards.
                      </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Rickey Martins</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="#0">Waste Management </a>
                  </span>
                </div>
              </div>
            </div>
 
          </div> */}
        </div>
      </div>
    </section>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;